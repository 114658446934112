@import '../../styles/media';
@import '../../styles/theme';

.root {
	outline: none;
	border: 1px solid;
	border-radius: 5px;
	padding: 10px 14px;
  line-height: 1em;
	@include theme-transitions(
		$color: var(--text-color),
		$background-color: var(--background),
		$border-color: var(--control-border)
	);

	&:disabled {
		background-color: var(--control-disabled);
	}

  &:not(:disabled) {
    @include hover {
      border-color: var(--control-active-border);
    }
  }
}
