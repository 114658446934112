@import '../../styles/media';
@import '../../styles/theme';

.root {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;

  font-size: 16px;
	cursor: pointer;
	outline: none;
	border: 1px solid;
	border-radius: 5px;

	&:not([multiple]) {
		padding: 10px 14px;
		background-image: url("../../assets/icons/chevron-down.svg");
		background-repeat: no-repeat;
		background-size: 18px;
		background-position: right 6px center;
    @include theme-transitions(
      $color: var(--text-color),
      $background-color: var(--background),
      $border-color: var(--control-border)
    );

    option {
      @include theme-transitions(
        $color: var(--text-color),
        $background-color: var(--background)
      );
    }
	}

	&[multiple] {
		@include theme-transitions(
			$background-color: transparent,
			$border-color: var(--control-border)
		);

		option {
			padding: 6px 14px;
			color: var(--weak-text-color);
			transition: color var(--theme-change-transition), background-color 0.15s;

			&:checked {
				background-color: var(--control-border) !important;
				color: var(--text-color);
				transition:
					color var(--theme-change-transition),
					background-color var(--theme-change-transition);
			}

			@include hover {
				background-color: var(--control-disabled);
			}
		}
	}

	&:disabled {
    cursor: default;
		background-color: var(--control-disabled);
	}

	&:not(:disabled) {
    @include hover {
      border-color: var(--control-active-border);
    }
	}
}
