@import 'styles/colors.scss';
@import 'styles/theme.scss';
@import 'styles/media.scss';

$offset-right: 30px;
$offset-bottom: 120px;
$visible-transition: 0.4s;
$toggle-height: 23px;
$menu-transition: 0.2s;

.root {
  --font: 16px;
  --width: 500px;
  --height: 800px;
  --radius-top: 12px;
  --radius-bottom: 12px;
  --nav-height: 50px;
  --footer-gap: 15px;
  --left: auto;
  --right: #{$offset-right};
  --bottom: #{$offset-bottom};
  --max-width: calc(100vw - #{$offset-right} * 2);
  --max-height: calc(100% - #{$offset-bottom} - #{$offset-right});
  --opacity: 0;
  --visibility: hidden;
  --transform: translateX(calc(var(--width) / 2));
  --bottom-padding: 0;

  @include tablet-portrait {
    --font: 14px;
    --nav-height: 40px;
    --footer-gap: 10px;
  }

  @include mobile {
    --width: auto;
    --radius-top: 20px;
    --radius-bottom: 0;
    --left: 0;
    --right: 0;
    --bottom: 0;
    --max-width: none;
    --max-height: calc(100% - 20px);
    --opacity: 1;
    --visibility: visible;
    --transform: translateX(0);
    --height: calc( #{$toggle-height} + var(--footer) * 1px - var(--footer-gap) );
    --bottom-padding: calc( var(--footer) * 1px );
    transition: box-shadow var(--theme-change-transition), height $visible-transition;
  }

  &.visible {
    --opacity: 1;
    --visibility: visible;
    --transform: translateX(0);

    @include mobile {
      --height: 100%;
    }
  }

  position: fixed;
  left: var(--left);
  right: var(--right);
  bottom: var(--bottom);
  width: var(--width);
  height: var(--height);
  max-width: var(--max-width);
  max-height: var(--max-height);
  padding-bottom: var(--bottom-padding);
  border-top-left-radius: var(--radius-top);
  border-top-right-radius: var(--radius-top);
  border-bottom-left-radius: var(--radius-bottom);
  border-bottom-right-radius: var(--radius-bottom);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  user-select: none;
  box-shadow: 0 4px 14px 0 rgb(0, 0, 0, 0.25);
  opacity: var(--opacity);
  visibility: var(--visibility);
  transform: var(--transform);
  transition: box-shadow var(--theme-change-transition),
    opacity $visible-transition, visibility $visible-transition,
    transform $visible-transition;

  @include dark {
    box-shadow: 0 4px 14px 0 rgb(255, 255, 255, 0.25);
  }

  .navbar {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px 15px;
    gap: 10px;
    height: auto;
    border-top-left-radius: var(--radius-top);
    border-top-right-radius: var(--radius-top);
    @include themed-back(--header);
    overflow: hidden;

    .draggable {
      display: none;
    }

    @include mobile {
      .draggable {
        margin: -10px -15px -5px;
        height: $toggle-height;
        min-height: $toggle-height;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        background: none;
        z-index: 3;

        &:after {
          content: '';
          width: 100px;
          height: 3px;
          border-radius: 2px;
          @include themed-back(--control-border);
        }
      }
    }

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      svg {
        width: auto;
        height: calc(var(--nav-height) * 0.6);
      }

      .coach {
        width: auto;
        height: calc(var(--nav-height) * 0.6 + 50px);
        margin: -25px 0 -25px -20px;
      }
    }

    select {
      height: calc(var(--font) * 2.2);
      padding: 0 6px;
      font-size: var(--font);
    }
  }

  .dialog {
    flex: 1;
    display: flex;
    overflow: hidden;
    position: relative;
    @include themed-back(--background);

    & > .loading {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-self: center;
      justify-content: center;
      opacity: 0.1;
    }

    .auth {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-self: stretch;
      justify-content: center;
      gap: 10px;
      margin: 0 auto;
      max-width: calc(100% - 80px);

      div.description {
        position: relative;

        p {
          position: absolute;
          left: 0;
          bottom: 20px;
          width: 100%;
          font-size: 0.9em;
          line-height: 1.2em;
          padding: 0;
          margin: 0;
          @include themed-color(--text-color);
        }
      }

      p.error {
        font-size: 0.8em;
        min-height: 1em;
        margin-top: -1em;
        color: var(--red);
      }

      & > div {
        display: flex;
        flex-direction: row;
        gap: 10px;

        button {
          flex-grow: 1;
          padding: 0;
          font-size: var(--font);

          &:last-child {
            flex-grow: 3;
          }
        }
      }

      .policyLink {
        font-weight: bold;
      }
    }

    .password {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-self: stretch;
      justify-content: center;
      gap: 10px;
      margin: 0 auto;
      max-width: calc(100% - 80px);

      input.error {
        border-color: var(--red);
      }

      div {
        display: flex;
        flex-direction: row;
        gap: 10px;

        button {
          flex-grow: 1;
          font-size: var(--font);
        }
      }
    }

    .configs {
      --max-width: 400px;
      --buttons-direction: row;

      &:not(:has(select)) {
        --max-width: 200px;
        --buttons-direction: column;
      }

      display: flex;
      flex-direction: column;
      align-self: stretch;
      justify-content: center;
      gap: 10px;
      margin: 0 auto;
      width: calc(100% - 40px);
      max-width: var(--max-width);

      & > div {
        display: flex;
        flex-direction: var(--buttons-direction);
        flex-wrap: wrap;
        gap: 10px;

        button {
          flex-grow: 1;

          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }

    .messages {
      flex: 1;
      padding: 10px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
      user-select: text;

      @include mobile {
        border-radius: 0;
      }

      .directive {
        position: sticky;
        top: 0;
        margin-bottom: -20px;
        height: 0;
        z-index: 2;

        & > div {
          position: relative;
          display: grid;
          grid-template-columns: auto 1fr auto;
          grid-template-rows: repeat(2, auto);
          row-gap: 5px;
          padding: 10px;
          margin: -5px;
          border-radius: 10px;
          overflow: hidden;
          backdrop-filter: blur(20px);
          box-shadow: 0 2px 10px -8px var(--text-color);
          transition: box-shadow var(--theme-change-transition);

          & > div:first-child {
            grid-area: 1 / 1 / 3 / 2;
            position: relative;
            display: grid;
            align-content: center;
            padding: 8px;
            margin-right: 15px;
            border-radius: 50px;
            overflow: hidden;

            img {
              height: 20px;
            }

            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
              @include themed-back(--blue-pale);
              opacity: 0.1;
              z-index: -1;
            }
          }

          & > button:last-child {
            align-self: flex-start;
            grid-area: 1 / 3 / 3 / 4;
            margin-left: 5px;
            padding: 0;
            height: auto;
            background: none;

            img {
              width: 12px;
              height: 12px;
              opacity: 0.8;
              filter: invert(0.5);
            }
          }

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            @include themed-back(--header);
            opacity: 0.5;
            z-index: -1;
          }

          p {
            font-size: 0.8em;
            @include themed-color(--weak-text-color);

            strong {
              font-weight: 500;
              @include themed-color(--text-color);
            }
          }
        }
      }

      & > *.user {
        align-self: flex-end;
        border-top-right-radius: 3px;
        @include theme-transitions(
          $color: var(--text-color),
          $background-color: var(--user-speech)
        );

        & + .user {
          margin-top: -8px;
        }
      }

      & > *.assistant {
        align-self: flex-start;
        border-top-left-radius: 3px;
        @include theme-transitions(
          $color: var(--text-color),
          $background-color: var(--assistant-speech)
        );

        & + .assistant {
          margin-top: -8px;
        }
      }

      & > p {
        position: relative;
        padding: 16px 20px;
        min-width: 5.8em;
        border-radius: 10px;
        max-width: 90%;
        font-size: var(--font);
        line-height: 1.2em;

        &.loading {
          display: flex;
          flex-direction: row;
          align-items: center;
          opacity: 0.5;

          .spinner {
            margin-right: 10px;
          }

          span {
            display: inline-block;
            padding-right: 2px;
            animation: loading 0.8s linear infinite;

            &:nth-child(1) {
              animation-delay: 0s;
            }
            &:nth-child(2) {
              animation-delay: 0.1s;
            }
            &:nth-child(3) {
              animation-delay: 0.2s;
            }
          }
        }

        &.completed {
          @include themed-color(--weak-text-color);
        }

        span.date {
          position: absolute;
          right: 1em;
          bottom: 0.6em;
          font-size: 0.6em;
          line-height: 0.6em;
          opacity: 0.5;
          user-select: none;
        }

        &.editing {
          opacity: 0.5;
        }

        &.error {
          color: var(--red);
        }
      }

      & > img {
        border-radius: 10px;
        min-width: 50px;
        max-width: 60%;
        cursor: pointer;
        transition: filter 0.2s !important;

        @include hover {
          filter: brightness(1.1);
        }
      }
    }

    .pause {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @include themed-back(--background);
        opacity: 0.7;
      }
    }

    .chats {
      flex: 1;
      display: flex;
      flex-direction: column;

      .loading {
        flex: 1;
        align-items: center;
      }

      .empty {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        @include themed-color(--weak-text-color);
      }

      &:not(.empty) > div:first-child {
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding: 10px;
        padding-top: 0;
        @include themed-back(--header);

        select {
          flex: 1;
        }

        button, select {
          font-size: 0.9em;
          height: auto;
          padding: 7px 10px;
        }
      }
    }

    .policy {
      flex: 1;
      display: flex;
      flex-direction: column;
      z-index: 2;

      & > div:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        padding: 10px;
        padding-top: 0;
        @include themed-back(--header);

        span {
          flex-grow: 1;
          font-size: 1.2em;
          font-weight: bold;
          @include themed-color(--text-color);
        }

        button {
          font-size: 0.9em;
          height: auto;
          padding: 7px 10px;
        }
      }

      & > div:last-child {
        padding: 10px;
        user-select: text;
        overflow: auto;
        line-height: 1.2em;
        @include themed-color(--text-color);

        p {
          margin: 10px 0;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        strong {
          font-weight: bold;
        }

        ol {
          list-style: decimal;
          list-style-position: inside;

          & > li {
            margin: 10px 0;

            & > p:first-child {
              display: inline;
              white-space: normal;
            }
          }
        }

        ul {
          list-style: disc;
          padding-left: 25px;

          li {
            margin: 5px 0;
          }
        }
      }

      .accepted {
        padding: 10px;
        @include themed-color(--weak-text-color);
        text-align: right;
        font-size: 0.9em;
      }
    }

    .over {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      @include themed-back(--background);
    }

    .connection {
      position: absolute;
      left: 0;
      bottom: calc( -1em - 8px );
      opacity: 0;
      visibility: hidden;
      width: 100%;
      padding: 4px 10px;
      font-size: 0.8em;
      backdrop-filter: blur(5px);
      transition: 0.2s;

      &.offline {
        bottom: 0;
        opacity: 1;
        visibility: visible;
        color: var(--red);
        background-color: rgba(255, 0, 50, 0.3);
      }

      &.online {
        bottom: 0;
        opacity: 1;
        visibility: visible;
        color: var(--green);
        background-color: rgba(0, 255, 50, 0.2);
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    z-index: 2;
    padding: var(--footer-gap);
    gap: var(--footer-gap);
    border-bottom-left-radius: var(--radius-bottom);
    border-bottom-right-radius: var(--radius-bottom);
    @include themed-back(--header);

    @include mobile {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }

    button.microphone,
    button.volume {
      position: relative;

      img {
        z-index: 2;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--red);
        z-index: 1;
        opacity: 0;
        transition: opacity 0.3s;
      }
    }

    button.microphone {
      &.recording {
        &:before {
          opacity: 1;
        }

        img {
          animation: recording 2s linear infinite;
        }
      }

      &.recognizing > img {
        animation: recognizing 1.5s linear infinite;
      }

      &:disabled {
        background-image: none;
        border: 1px solid;
        @include theme-transitions(
          $background-color: var(--background),
          $border-color: var(--control-border)
        );

        img {
          opacity: 0.3;
          transition: filter var(--theme-change-transition);

          @include light {
            filter: invert(1);
          }
        }
      }
    }

    button.volume.silent:before {
      opacity: 1;
    }

    .status {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.5;
      font-size: calc( var(--font) * 0.9 );
      @include themed-color(--weak-text-color);
    }

    .input {
      flex-grow: 1;
      position: relative;
      display: flex;

      textarea {
        width: 100%;
        min-width: 0;
        min-height: calc(var(--font) * 2.2);
        max-height: calc(var(--font) * 8);
        height: calc(var(--height) + 2px);
        padding: calc(var(--font) * 0.5) 6px;
        padding-right: calc(var(--font) * 2.2);
        font-size: var(--font);
        resize: none;
      }

      button {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        background: none;
        width: calc(var(--font) * 2.2);
        height: calc(var(--font) * 2.2);

        &:disabled > img {
          filter: grayscale(1);
          opacity: 0.8;
        }
      }
    }

    button, a {
      height: calc(var(--font) * 2.2);
      padding: 0 14px;

      img {
        width: calc(var(--font) * 1.2);
        height: calc(var(--font) * 1.2);
      }
    }

    .menu {
      position: relative;

      a[data-open] {
        background-image: none;
        border: 1px solid;
        @include theme-transitions(
          $background-color: var(--background),
          $border-color: var(--control-border)
        );
      }

      button[data-close] {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        //height: 100%;
        visibility: hidden;
        opacity: 0;
        transition:
          opacity $menu-transition,
          visibility $menu-transition;
        pointer-events: none;

        * { pointer-events: none; }

        img {
          width: 13px;
          height: 13px;
        }
      }

      div[data-items] {
        position: absolute;
        right: 0;
        bottom: calc( 100% + 20px );
        padding: 5px 0;
        border: 1px solid;
        border-radius: 5px;
        visibility: hidden;
        opacity: 0;
        transform: translateY(20px);
        background-color: var(--header);
        border-color: var(--control-border);
        transition:
          background-color var(--theme-change-transition),
          border-color var(--theme-change-transition),
          opacity $menu-transition,
          visibility $menu-transition,
          transform $menu-transition;
        pointer-events: none;

        * { pointer-events: none; }

        button, a {
          width: 100%;
          border-radius: 0;
          padding: 0 20px;
          justify-content: flex-start;
          height: 30px;
          font-size: 14px;
          font-weight: normal;
          background: none;
          white-space: nowrap;
          @include theme-transitions(
            $color: var(--text-color)
          );

          &:disabled, &[disabled] {
            opacity: 0.3;
          }

          span {
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: text-decoration-color 0.2s;
          }

          @include hover {
            span {
              text-decoration-color: var(--text-color);
            }
          }
        }
      }

      a[data-open]:focus,
      div[data-items]:focus-within,
      div[data-items]:active {
        & ~ button[data-close] {
          visibility: visible;
          opacity: 1;
          pointer-events: auto;
        }
        &[data-items], & ~ div[data-items] {
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
          pointer-events: auto;

          * { pointer-events: auto; }
        }
      }

      a[data-open][disabled] {
        & ~ button[data-close] {
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        }
        & ~ div[data-items] {
          visibility: hidden;
          opacity: 0;
          transform: translateY(20px);
          pointer-events: none;

          * { pointer-events: auto; }
        }
      }
    }
  }
}

.viewer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20000;
}

@keyframes recording {
  0% { transform: scale(1); }
  25% { transform: scale(1.1); }
  50% { transform: scale(1); }
  75% { transform: scale(0.9); }
  100% { transform: scale(1); }
}

@keyframes recognizing {
  0% { transform: rotate(0); }
  100% { transform: rotate(360deg); }
}

@keyframes loading {
  0% { transform: translateY(0); }
  50% { transform: translateY(-3px); }
  100% { transform: translateY(0); }
}
