@import '../../styles/media';
@import '../../styles/theme';

$box-size: 20px;
$tick-size: 12px;
$radius: 4px;

$gradient: linear-gradient(90deg,
  var(--control-gradient-1),
  var(--control-gradient-2)
);

.root {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  input {
    display: none;

    &:checked + .box {
      background: $gradient;
    }

    &:not(:checked) + .box {
      border: 1px solid var(--control-colored);

      svg {
        display: none;
      }
    }
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: $box-size;
    min-height: $box-size;
    border-radius: $radius;

    svg {
      width: $tick-size;
      height: $tick-size;

      path {
        @include theme-transitions(
          $fill: var(--background)
        );
      }
    }
  }

  .content {
    @include themed-color(--text-color);
  }
}

