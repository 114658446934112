@import '../../styles/media';
@import '../../styles/theme';

$width: 30px;
$height: 14px;
$toggle: 22px;
$icon: 12px;

$toggle-pos: ( $height - $toggle ) / 2;
$icon-pos: ( $toggle - $icon ) / 2;

:root {
  --switch-transition: 0.3s;
}

.root {
  cursor: pointer;
  display: block;
  position: relative;
  width: $width;
  min-width: $width;
  height: $height;
	user-select: none;

  @include hover {
    .back {
      opacity: 0.3;
    }
  }

  input {
    display: none;

    &:checked + .toggle {
      left: calc( 100% - #{$toggle-pos} - #{$toggle} );
    }

    &:not(:checked) + .toggle > img:first-child {
      opacity: 1;
    }

    &:checked + .toggle > img:last-child {
      opacity: 1;
    }
  }
}

.back {
  width: 100%;
  height: 100%;
  border-radius: calc(#{$height} / 2);
  opacity: 0.2;
  background-color: var(--control-colored);
  z-index: 1;
  transition: opacity 0.2s;
}

.toggle {
  width: $toggle;
  height: $toggle;
  border-radius: calc(#{$toggle} / 2);
  position: absolute;
  left: $toggle-pos;
  top: $toggle-pos;
  background: linear-gradient(90deg,
    var(--control-gradient-1),
    var(--control-gradient-2)
  );
  transition: left var(--switch-transition);
  z-index: 2;

  .icon {
    width: $icon;
    height: $icon;
    position: absolute;
    left: $icon-pos;
    top: $icon-pos;
    opacity: 0;
    transition: opacity var(--switch-transition);

    &:not([src]) {
      display: none;
    }
  }
}
