@import '../../styles/media';
@import '../../styles/theme';

$gradient: linear-gradient(90deg,
	var(--control-gradient-1),
	var(--control-gradient-2)
);

$radius: 5px;

.root {
	--height: 40px;
	--padding: 0 30px;
	--icon-size: 20px;
	--gap: 10px;
	--cursor: pointer;
	--back: #{$gradient};
	--pointer-events: auto;

	&.large {
		--height: 60px;
		--padding: 0 45px;
		--icon-size: 24px;
		--gap: 20px;
	}

  &:disabled, &[disabled] {
		--cursor: default;
		--pointer-events: none;

		&:not(.loading) {
			opacity: 0.8;
		}
	}

	cursor: var(--cursor);
	pointer-events: var(--pointer-events);
	outline: none;
	border: 0;
	border-radius: $radius;
	height: var(--height);
	padding: var(--padding);
	font-size: 18px;
	font-weight: bold;
  text-decoration: none;
	color: var(--control-colored-text);
	background: var(--back);
	background-origin: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
  justify-content: center;
	gap: var(--gap);
	position: relative;
	overflow: hidden;
	transition: opacity 0.2s, filter 0.15s;

	& > img, & > span {
		opacity: 1;
		visibility: visible;
		transition: opacity 0.2s, visibility 0.2s;
	}

	&.invert {
		--back: none;

		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			box-sizing: border-box;
			border-radius: $radius;
			border: 1px solid transparent;
			background: #{$gradient};
			-webkit-mask:
				linear-gradient(#fff 0 0) padding-box,
				linear-gradient(#fff 0 0);
			-webkit-mask-composite: destination-out;
			mask-composite: exclude;
		}

		& > span {
			background: #{$gradient};
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	img {
		width: var(--icon-size);
		height: var(--icon-size);
		object-fit: contain;
	}

	&.loading {
		--pointer-events: none;

		& > img, & > span {
			opacity: 0;
			visibility: hidden;
		}

		& > div {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	@include hover {
		filter: brightness(1.1);
	}
}
