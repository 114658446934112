@import "../../styles/colors";
@import "../../styles/theme";

.root {
	--color: var(--text-color);

	&.invert {
		--color: var(--background);
	}

	&.dark {
		--color: var(--black);
	}

	&.light {
		--color: var(--white);
	}

	display: inline-block;
	position: relative;
	width: var(--size);
	height: var(--size);

	& > div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: calc( var(--size) * 0.8 );
		height: calc( var(--size) * 0.8 );
		margin: calc( var(--size) * 0.1 );
		border: calc( var(--size) * 0.1 ) solid;
		border-radius: 50%;
		animation: loading-animate 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		@include theme-transitions(
			$border-color: var(--color) transparent transparent transparent
		);


		&:nth-child(1) { animation-delay: -0.45s; }
		&:nth-child(2) { animation-delay: -0.3s; }
		&:nth-child(3) { animation-delay: -0.15s; }
	}
}

@keyframes loading-animate {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
