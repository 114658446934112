@use 'sass:list';
@use 'sass:map';
@use 'sass:meta';

html {
  --theme-change-transition: 0.3s linear 0s;

  --control-colored: var(--blue-pale);
  --control-gradient-1: var(--blue);
  --control-gradient-2: var(--purple);
  --control-colored-text: var(--white);

  &[data-theme-initing] {
    --theme-change-transition: 0;
  }


  // theme = light

  --background: var(--white);
  --text-color: var(--black);
  --weak-text-color: var(--grey-6);

  --scroll-bar: var(--grey-3);
  --scroll-bar-hover: var(--grey-4);

  --control-border: var(--grey-4);
  --control-active-border: var(--grey-6);
  --control-disabled: var(--grey-1);

  --header: var(--grey-1);

  --user-speech: var(--light-green);
  --assistant-speech: var(--light-blue);


  @media not print {
    &[data-bs-theme='dark'] {
      --background: var(--black);
      --text-color: var(--white);
      --weak-text-color: var(--grey-5);

      --scroll-bar: var(--grey-8);
      --scroll-bar-hover: var(--grey-7);

      --control-border: var(--grey-7);
      --control-active-border: var(--grey-5);
      --control-disabled: var(--grey-9);

      --header: var(--grey-9);

      --user-speech: var(--dark-green);
      --assistant-speech: var(--dark-blue);
    }
  }
}

@mixin light {
  @media not print {
    html:not([data-bs-theme='dark']) & {
      @content;
    }
  }
}

@mixin dark {
  @media not print {
    html[data-bs-theme='dark'] & {
      @content;
    }
  }
}

@mixin theme-transitions($properties...) {
  $transitions: ();
  @each $property, $value in meta.keywords($properties) {
    #{$property}: $value;
    $transitions: list.append($transitions, $property var(--theme-change-transition), comma);
  }
  transition: $transitions;
}

@mixin themed-color($var) {
  @include theme-transitions(
      $color: var(#{$var})
  );
}

@mixin themed-back($var) {
  @include theme-transitions(
      $background-color: var(#{$var})
  );
}
