@import './styles/colors.scss';
@import './styles/theme.scss';
@import './styles/media.scss';

body:has(.templates:not(.configured)) {
  overflow: hidden;
}

.templates {
  img {
    width: 100%;

    &:last-child {
      display: none;
    }

    @include mobile {
      display: none;

      &:last-child {
        display: block;
      }
    }
  }
}

.opener {
  @include mobile {
    display: none;
  }
}
