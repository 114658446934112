@import '../../styles/media';
@import '../../styles/theme';

$size: 60px;
$offset: 30px;

.root {
	cursor: pointer;
	width: $size;
	height: $size;
	border: 0;
	border-radius: #{$size / 3};
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	right: $offset;
	bottom: $offset;
	background-image: linear-gradient(90deg,
		var(--control-gradient-1),
		var(--control-gradient-2)
	);
	z-index: 10000;
	transition: transform 0.15s, filter 0.15s;

	img {
		width: #{$size * 0.6};
		height: #{$size * 0.6};
		margin-top: #{$size * 0.1};
		object-fit: contain;
	}

	@include hover {
		transform: scale(1.1);
		filter: brightness(1.1);
	}
}
