@import 'styles/media';
@import 'styles/theme';

$border-radius: 4px;
$back-transition: 0.3s;

.root {
  position: relative;
  border-radius: $border-radius;
  white-space: nowrap;
  @include themed-back(--background);

  button {
    position: relative;
    padding: 0 12px;
    height: 32px;
    font-size: 14px;
    font-weight: bold;
    color: var(--text-color);
    background: none;
    border: 0;
    overflow: hidden;
    opacity: 0.5;
    cursor: pointer;
    transition: color $back-transition, opacity $back-transition;
    z-index: 2;

    @include hover {
      opacity: 0.7;
    }

    &.selected {
      color: var(--control-colored-text);
      opacity: 1;
    }
  }

  .back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc( var(--left) * 1px );
    width: calc( var(--width) * 1px );
    border-radius: $border-radius;
    background: linear-gradient(90deg,
      var(--control-gradient-1),
      var(--control-gradient-2)
    );
    transition: left $back-transition, width $back-transition;
    z-index: 1;
  }
}
