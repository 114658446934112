@import 'reset-css';
@import './media';
@import './theme';

:root {
  --padding-horizontal: #{$padding-desktop-horizontal};
  --padding-vertical: #{$padding-desktop-vertical};

  @include tablet-landscape {
    --padding-horizontal: #{$padding-landscape-horizontal};
    --padding-vertical: #{$padding-landscape-vertical};
  }

  @include tablet-portrait {
    --padding-horizontal: #{$padding-portrait-horizontal};
    --padding-vertical: #{$padding-portrait-vertical};
  }

  @include mobile {
    --padding-horizontal: #{$padding-mobile-horizontal};
    --padding-vertical: #{$padding-mobile-vertical};
  }
}

html {
  height:100%;
}

body {
  height:100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);

  @include themed-back(--background);

  @media (hover: hover) and (pointer: fine) {
    &::-webkit-scrollbar {
      background: var(--header);
    }
  }
}

a {
  color: var(--control-colored);
  transition: filter 0.2s;
  cursor: pointer;
  text-decoration: underline;

  @include hover {
    @include dark {
      filter: brightness(1.5);
    }

    @include light {
      filter: brightness(0.5);
    }
  }
}

#root {
  height: 100%;
}

@media (hover: hover) and (pointer: fine) {
  ::-webkit-scrollbar {
    width: 15px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--scroll-bar);
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--scroll-bar-hover);
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 10px;
  }
}

* {
  outline: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: inherit;

  @media (hover: hover) and (pointer: fine) {
    scrollbar-color: var(--scroll-bar) transparent;
    scrollbar-width: thin;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.stroke {
  stroke: var(--text-color);
  transition: stroke var(--theme-change-transition);
}

.fill {
  fill: var(--text-color);
  transition: fill var(--theme-change-transition);
}

.fill-revert {
  fill: var(--background);
  transition: fill var(--theme-change-transition);
}
