:root {
  --black: #17141f;
  --white: #fff;

  --grey-1: #f7f7f7;
  --grey-2: #f0eff7;
  --grey-3: #e4e4e4;
  --grey-4: #c1bfcf;
  --grey-5: #a39fb5;
  --grey-6: #7e7b8d;
  --grey-7: #565364;
  --grey-8: #363343;
  --grey-9: #24212d;
  --grey-10: #201c2e;

  --light-blue: #e1f1f8;
  --blue-pale: #7d7bf6;
  --blue: #7078f3;
  --dark-blue: #04445f;

  --purple: #a783fd;

  --light-green: #dff4d7;
  --green: #3da548;
  --dark-green: #353a33;

  --red: #f04444;
}
